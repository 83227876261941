import React, { Component } from "react";
import background from './/backpic.jpg';
import './App.css';

const REGISTER_URL = 'https://gaengefuercharly.ew.r.appspot.com/makequest';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      websiteNr: 0,
      quest: '',
      userSecret: '',
      validSecret: false,
    };
  }

  goToQuest = () => {
    this.setState({ websiteNr: 1 });
  }

  onChangeHandler = (event) => {
    let key = event.target.name;
    let val = event.target.value;
    this.setState({ [key]: val });
  };

  submitQuest = (event) => {
    event.preventDefault();
    var payload = JSON.stringify({
      userSecret: this.state.userSecret,
      quest: this.state.quest
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: payload,
    };

    fetch(REGISTER_URL, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.validSecret) {
          this.setState({ websiteNr: 2 });
        } else {
          // registration form is invalid - user already exists
          this.setState({ websiteNr: 3 });
        }
      })
      .catch((err) => {
        console.log("Something went wrong during the registration", err);
        this.setState({
          websiteNr: 3,
        });
      });
  }

  render() {
    let show;
    let intro = <div className='IntroText' style={{
      background: 'rgba(196, 196, 196, 0.6)',
      borderRadius: '10px', width: '70vw',
      textAlign: 'center'
    }}>
      <p>Hallo zusammen!<br></br>
        Wie schön, dass du es auf diese Seite geschafft hast. Wir möchten unserer guten Freundin Viola eine Freude bereiten
        und mit ihr ein personalisiertes Dare-Pong auf ihrer Bday-Party spielen &#127866; <br></br>
        Und dafür brauchen wir noch viele lustige Aufgaben &#128527; <br></br>
        Bock mitzumachen? Dann schlag doch jetzt deine geniale BeerPong Dare-Aufgabe vor!
      </p>
      <button onClick={this.goToQuest} style={{ backgroundColor: 'black', color: 'orange', borderRadius: '10px' }}>
        Let's get jucy &#127867;</button>
    </div>
    let questForm = <div className='questForm' style={{
      display: 'flex',
      flexFlow: 'column wrap',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <p style={{
        margin: '1vh',
        fontSize: '4vh',
        color: '#F1703C',
        textShadow: '0px 4px 4px rgba(68, 52, 50, 0.83)',
        textAlign: 'center'
      }}>DO YOU DARE TO MAKE A QUEST?</p>
      <form onSubmit={this.submitQuest} style={{
        display: 'flex',
        flexFlow: 'column wrap',
        alignItems: 'center',
      }}>
        <input type="text" name='quest' onChange={this.onChangeHandler} style={{
          height: '5vh', width: '45vw',
          background: 'rgba(196, 196, 196, 0.54)',
          borderRadius: '10px',
          fontSize: '3vh',
          margin: '1vh'
        }} />
        <label style={{ fontSize: 'small' }}>Passwort</label>
        <input type="password" name='userSecret' onChange={this.onChangeHandler} style={{
          height: '1.5vh', width: '30vw',
          background: 'rgba(196, 196, 196, 0.54)',
          borderRadius: '10px',
          margin: '1vh'
        }} />
        <button type="submit"
          style={{
            background: 'rgba(0, 0, 0, 0.8)',
            borderRadius: '20px',
            borderColor: 'black',
            fontSize: '3vh'
          }}>&#128293;</button>
      </form>

    </div>;
    let finalPage = <div className='end' style={{

      borderRadius: '10px', width: '70vw',
      textAlign: 'center'
    }}>
      <iframe
        title='Gracias'
        src="https://giphy.com/embed/oFICw7RuFMVG4Les1i"
        width="100%"
        frameBorder="0"
        className="giphy-embed"
        allowFullScreen
      ></iframe>
      <p style={{ fontSize: "0.1em" }}>
        <a href="https://giphy.com/gifs/ComedianHollyLogan-thank-you-gracias-correct-oFICw7RuFMVG4Les1i">
          via GIPHY
        </a>
      </p>
      <button onClick={this.goToQuest} style={{
        background: 'rgba(0, 0, 0, 0.8)',
        borderRadius: '20px',
        borderColor: 'black',
        fontSize: '2vh',
        color: 'orange'
      }}>Neuen Quest &#128526;</button>
    </div>
    let error = <div className='errorPage'>
      <h2>Invalid Password - Please try again</h2>
      <button onClick={this.goToQuest} style={{
        background: 'rgba(0, 0, 0, 0.8)',
        borderRadius: '20px',
        borderColor: 'black',
        fontSize: '2vh',
        color: 'orange'
      }}>Neuen Quest &#128526;</button>
    </div>
    if (this.state.websiteNr === 0) {
      show = intro;
    }
    if (this.state.websiteNr === 1) {
      show = questForm;
    }
    if (this.state.websiteNr === 2) {
      show = finalPage;
    }
    if (this.state.websiteNr === 3) {
      show = error;
    }
    return (
      <div className='frame' style={{
        width: '100vw', height: '100vh',
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        {show}
      </div>
    );

  }
}

export default App;
